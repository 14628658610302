import { ProductCardProps, ProductCardSimpleProps } from 'components/Product/ProductCard/types';
import config from 'config';
import { APP_EVENT_MESSAGES } from 'constants/appEvents';
import { ATP_MESSAGES } from 'constants/atp';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import type { CompareGroup } from 'types/Compare';
import { Feature, Product, RealTimeInfo } from 'types/Product';
import type { Signing } from 'types/Signing';
import { log } from 'utils/loggerUtil';
import { setNewCompareObject } from 'utils/productCompareUtil';
import { getPromotionTranslationKey } from 'utils/promotionUtil';
import { detectEmbedded } from 'utils/windowUtil';
import useAppEvents from './useAppEvents';
import { useAtpMessage } from './useAtpMessage';
import { useCriteoTracking } from './useCriteoTracking';
import useRouter from './useRouter';
import useSigning from './useSigning';
import useWishlist from './useWishlist';

const useProductCard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { isReady, locale } = useRouter();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [cookies, setCookie] = useCookies();
  const { mapAtpMessage } = useAtpMessage();

  const { getSponsoredSigning } = useSigning();
  const { addProduct, exists, removeProduct } = useWishlist();
  const { sendAppEvent } = useAppEvents();
  const { pushCriteoBeacon } = useCriteoTracking();

  const isWebview = detectEmbedded();

  const compareCookie = isReady ? (cookies[config.productCompareCookie.key] ?? {}) : {}; //Wait for router to be ready to prevent hydration errors
  const { comparedProductCodes: cookieComparedProductCodes } = compareCookie;

  const onWishlistClick = (product: Product) => {
    const existsInWishlist = exists(product.code);
    const tracking = product.tracking;

    if (tracking?.OnWishlistBeacon && pushCriteoBeacon) {
      pushCriteoBeacon(tracking.OnWishlistBeacon);
      log('ProductCard', 'Pushing OnWishlistBeacon for format', tracking.OnWishlistBeacon);
    }

    if (existsInWishlist) {
      return removeProduct(product);
    }

    return addProduct(product);
  };

  const onViewed = (tracking: Product['tracking']) => {
    if (tracking?.OnViewBeacon && pushCriteoBeacon) {
      pushCriteoBeacon(tracking.OnViewBeacon);
      log('Criteo - SP - ProductCard', 'Pushing OnViewBeacon for product', tracking.OnViewBeacon);
    }

    if (tracking?.OnFormatViewBeacon && pushCriteoBeacon) {
      pushCriteoBeacon(tracking.OnFormatViewBeacon);
      log('Criteo - SP - ProductCard', 'Pushing OnFormatViewBeacon for format', tracking.OnFormatViewBeacon);
    }
  };

  const onAddToCartOverride = async (product: Product, addToCart?: () => Promise<void>) => {
    if (product.tracking?.OnBasketChangeBeacon && pushCriteoBeacon) {
      pushCriteoBeacon(product.tracking.OnBasketChangeBeacon);
      log(
        'Criteo - SP - ProductCard',
        'Pushing OnBasketChangeBeacon for format',
        product.tracking.OnBasketChangeBeacon,
      );
    }
    setIsLoading(true);
    await addToCart?.();
    setIsLoading(false);
  };

  const onClickOverride = (product: Product, onClick?: () => void) => {
    if (product.tracking?.OnClickBeacon && pushCriteoBeacon) {
      pushCriteoBeacon(product.tracking.OnClickBeacon);
      log('Criteo - SP - ProductCard', 'Pushing OnClickBeacon for format', product.tracking.OnClickBeacon);
    }

    const isWebview = detectEmbedded();

    if (isWebview) {
      sendAppEvent({
        message: APP_EVENT_MESSAGES.OPEN_PRODUCT,
        params: { IdProduct: product.code },
      });
    }

    onClick?.();
  };

  const mapSigning = (signing: Signing) => {
    if (!signing.text || !signing.colour) return undefined;
    return {
      hex: signing?.colour,
      id: `${signing?.title ?? signing?.type}`,
      label: signing?.text,
    };
  };

  type MappedSigning = {
    hex: string;
    id: string;
    label: string;
  };
  const mapSignings = (signings: Product['signings']) =>
    signings?.reduce((prevSignings: MappedSigning[], signing) => {
      const mappedSigning = mapSigning(signing);
      if (mappedSigning) {
        prevSignings.push(mappedSigning);
      }
      return prevSignings;
    }, []);

  const mapProductPromotionsToPills = (promotions: Product['supplierPromotions'], useFullLabel = false) => {
    if (!promotions) return [];
    return promotions?.reduce((prevPromotions: { id: string; label: string }[], promotion) => {
      const { code, label, type } = promotion;
      if (useFullLabel && label) {
        return [...prevPromotions, { id: code, label }];
      }
      if (!useFullLabel && type) {
        const promotionTranslationKey = getPromotionTranslationKey(type);
        const mappedLabel = formatMessage({ id: promotionTranslationKey });
        if (mappedLabel) {
          return [...prevPromotions, { id: code, label: mappedLabel }];
        }
      }
      log('ProductCard', 'Could not map promotion to label', promotion);
      return prevPromotions;
    }, []);
  };

  const mapProductTitle = (brandName?: string, productName?: string) =>
    [brandName, productName].filter(Boolean).join(' ');

  const mapAttributes = (features?: Feature[]) =>
    features?.map(
      (feature) =>
        `${feature.name}: ${feature.featureValues
          ?.map((featureValue) => {
            switch (featureValue.value) {
              case 'false':
                return formatMessage({ id: 'toggle_switch_no' });
              case 'true':
                return formatMessage({ id: 'toggle_switch_yes' });
              default:
                return `${featureValue.value} ${feature.featureUnit?.name ?? ''}`;
            }
          })
          .join(', ')}`,
    );

  const mapProductImage = (product: Product) => {
    if (product?.images?.[0]?.url) {
      return {
        alt: product.images[0].alt ?? product.name ?? 'Product image',
        src: product.images[0].url,
      };
    }
    if (product?.image?.url) {
      return {
        alt: product.image.alt ?? product.name ?? 'Product image',
        src: product.image.url,
      };
    }
    return undefined;
  };

  const mapProductCardSimpleProps = ({
    onAddToCart,
    onClick,
    product,
  }: {
    onAddToCart?: () => Promise<void>;
    onClick?: () => void;
    product: Product;
  }) => {
    const energyLabel = product.energyLabel?.energyCode ? product.energyLabel : undefined;
    const href = product.url ?? `/${locale}/p/${product.code}`;
    const image = mapProductImage(product);
    const inWishlist = exists(product.code);
    const pill = mapProductPromotionsToPills(product.supplierPromotions)[0];
    const price = product.price?.value;
    const rating = {
      quantity: product.numberOfReviews ?? 0,
      value: product.averageRating,
    };
    const signing = product.isSponsored ? getSponsoredSigning(product.legalInfo) : mapSignings(product.signings)?.[0];
    const strikePrice = product.strikePrice?.value;
    const title = mapProductTitle(product?.brand?.name, product?.name);
    const endDate = product.countDownEndDate;

    const common: ProductCardSimpleProps = {
      endDate,
      energyLabel,
      href,
      image,
      inWishlist,
      onAddToCart, // No override for simple product card, we dont want to show the button
      onClick: () => onClickOverride(product, onClick),
      onViewed: () => onViewed(product.tracking),
      onWishlistClick: () => onWishlistClick(product),
      pill,
      price,
      rating,
      signing,
      strikePrice,
      title,
    };

    return common;
  };

  const mapProductCardProps = ({
    compareGroup: initialCompareGroup,
    onAddToCart,
    onClick,
    product,
    realtimeInfo,
  }: {
    compareGroup?: CompareGroup;
    onAddToCart?: () => Promise<void>;
    onClick?: () => void;
    product: Product;
    realtimeInfo?: RealTimeInfo;
  }) => {
    const common = mapProductCardSimpleProps({ onAddToCart, onClick, product });

    const compareGroup = initialCompareGroup ?? product.compareGroup;
    const isCompared = cookieComparedProductCodes?.includes(product.code);
    const sponsoredSigning = product.isSponsored ? getSponsoredSigning(product.legalInfo) : undefined;
    const signings = mapSignings(product.signings) ?? [];
    const pills = mapProductPromotionsToPills(product.supplierPromotions, true);

    const {
      addToCartMessage,
      atpDescription,
      canBeSold,
      deliveryPromise,
      label: atpLabel,
      preOrderLabel,
      stockMessage,
      variant: atpVariant,
    } = mapAtpMessage({ product, realtimeInfo });

    const disabledAddToCartButton = addToCartMessage !== ATP_MESSAGES.BUTTON_STOCK || !canBeSold;

    const availableInStores = product.availableInStores ?? [];

    const atp = {
      atpDescription,
      availableInStores,
      canBeSold,
      deliveryPromise,
      label: atpLabel,
      preOrderLabel,
      stockMessage,
      variant: atpVariant,
    };

    const attributes = mapAttributes(product.topClassifications?.features);

    const buttonLabel = addToCartMessage ? formatMessage({ id: addToCartMessage }) : '';
    const code = product.code;
    const onCompare = compareGroup
      ? () => setNewCompareObject(product, compareCookie, compareGroup, dispatch, setCookie)
      : undefined;

    const fullProps: ProductCardProps = {
      ...common,
      atp,
      attributes,
      buttonLabel,
      code,
      disabledAddToCartButton,
      isCompared,
      loading: isLoading,
      onAddToCart: isWebview ? undefined : () => onAddToCartOverride(product, onAddToCart),
      onCompare,
      pills,
      product,
      signings,
      sponsoredSigning,
    };

    return fullProps;
  };

  return { mapProductCardProps, mapProductCardSimpleProps };
};

export default useProductCard;
