import { EnergyLabel } from 'components/Layout';
import { PriceNew, StrikePriceNew } from 'components/PriceNew/PriceNew';
import { useElementLoaded } from 'hooks/useElementLoaded';
import { useElementViewed } from 'hooks/useElementViewed';
import { useIntl } from 'react-intl';
import {
  Button,
  Countdown,
  Link,
  ProductCardContent,
  ProductCardFooter,
  ProductCardImage,
  ProductCardImageWrapper,
  ProductCardSigning,
  ProductCardTitle,
  ProductCardWishlistIconButton,
  ProductCard as ProductCardWrapper,
  Rating,
} from 'ui';
import { Badge } from 'ui/src/Badge/Badge';
import { cn } from 'utils/cn';
import { ProductCardSimpleProps } from './types';

const ProductCardSimple = ({
  className,
  endDate,
  energyLabel,
  href,
  image,
  inWishlist = false,
  loading,
  newTab,
  onAddToCart,
  onClick,
  onLoaded,
  onViewed,
  onWishlistClick,
  pill,
  price,
  rating,
  signing,
  strikePrice,
  title,
}: ProductCardSimpleProps) => {
  const { formatMessage } = useIntl();
  const { ref } = useElementViewed(onViewed);
  useElementLoaded(onLoaded);

  return (
    <ProductCardWrapper ref={ref} className={cn('relative gap-1 border-accent-40 md:gap-3', className)}>
      <ProductCardWishlistIconButton
        className={'absolute left-2 top-2 z-base'}
        inWishlist={inWishlist}
        onWishlistClick={onWishlistClick}
      />

      {signing && (
        <ProductCardSigning
          className="absolute right-0 top-0 z-base min-w-[90px] max-w-3/4 justify-center rounded-br-none rounded-ss-none py-1"
          key={signing.id}
          signing={signing}
        />
      )}

      <div className="relative">
        <Link href={href} onClick={onClick} newTab={newTab} prefetch={false}>
          <ProductCardImageWrapper className="h-[160px]">
            {image && <ProductCardImage useNext fill nextSizes={{ default: 160 }} src={image.src} alt={image.alt} />}
            {endDate && (
              <Countdown
                className="absolute bottom-0 left-0 z-base bg-opacity-80"
                date={endDate}
                dayLabel={formatMessage({ id: 'product_countdown_days' })}
                hourLabel={formatMessage({ id: 'product_countdown_hours' })}
                minuteLabel={formatMessage({ id: 'product_countdown_minutes' })}
                secondLabel={formatMessage({ id: 'product_countdown_seconds' })}
              />
            )}
          </ProductCardImageWrapper>
        </Link>
        {energyLabel && (
          <EnergyLabel energyLabel={energyLabel} flipHorizontal className="absolute left-0 top-1/2 -translate-y-1/2" />
        )}
      </div>

      <ProductCardContent className="mt-auto gap-3">
        <div className="flex gap-2">
          {!!rating.value && <Rating>{rating.value}</Rating>}
          {!!pill && <Badge key={pill.id}>{pill.label}</Badge>}
        </div>

        <ProductCardTitle
          className="h-10 text-sm md:h-12 md:text-base"
          newTab={newTab}
          onClick={onClick}
          href={href}
          prefetch={false}
        >
          {title}
        </ProductCardTitle>

        {!!price && (
          <div className="flex items-center gap-2.5">
            <PriceNew className="text-base md:text-[1.25rem]">{price}</PriceNew>
            {!!strikePrice && <StrikePriceNew className="text-sm md:text-base">{strikePrice}</StrikePriceNew>}
          </div>
        )}
      </ProductCardContent>

      {onAddToCart && (
        <ProductCardFooter className="justify-end">
          <Button loading={loading} onClick={onAddToCart} icon={{ name: 'cart-shopping', styling: 'far' }} />
        </ProductCardFooter>
      )}
    </ProductCardWrapper>
  );
};

export { ProductCardSimple };
